// import Forgot_Password from  '../../   /components/forgotPassword/forgotPassword'
import React from 'react'
import Forgot_Password from "../../components/forgotPassword/forgotPassword"

export default function ResetPassword() {
  return (
    <div>
        <Forgot_Password/>
    </div>
  )
}
