import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const data = [
//   {
//     name: 'Page A',
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: 'Page B',
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: 'Page C',
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: 'Page D',
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: 'Page E',
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: 'Page F',
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: 'Page G',
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

export default function Barcharts(){

    const data = [
        { day: 'Mon', value: 24 },
        { day: 'Tue', value: 18 },
        { day: 'Wed', value: 28 },
        { day: 'Thu', value: 21 },
        { day: 'Fri', value: 32 },
        { day: 'Sat', value: 28 },
        { day: 'Sun', value: 30 },
        ];
    
      return (
        <>
        <div>Orders</div>
            <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" scale="point" padding={{ left: 50, right: 50 }}  />
            <YAxis />
            <Tooltip />
            <Bar dataKey="day" fill="#008000" />
            <Bar dataKey="value" fill="#008000" />
          </BarChart>
        </ResponsiveContainer>
        </>

      );
    };
