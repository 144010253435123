"use client"
import Login from '../../components/login/login'
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.js';

export default function SignIn() {
  return (
    <div>
        <Login/>
    </div>
  )
}
